import React, { useState } from "react"
import { AiFillLinkedin } from "react-icons/ai"
import { IconContext } from "react-icons";
import { kebabCase } from "lodash";
import logo from "../../content/assets/img/logo.png"
import iconMenu from "../../content/assets/img/icon-menu.png"
import { Link } from "gatsby"
import "./topmenu.css"
import MobileMenu from "./mobile-menu"
import LanguageSwitcher from "./languageSwitcher"
import slugHandler from "../api/slugHandlerBrowser";

function TopMenu(props) {
    let nav = props.translation.nav;
    let jobs = props.jobs;
    let listJobs = props.listJobs;
    let [mobileMenuVisible, setMobileMenuVisible] = useState(false);

    return (
        <nav className="bot-menu color-black">
            <div className="bot-menu-in container-800">
                <div className="bot-menu-logo">
                    <Link to="/#home">
                    <img src={logo} alt="Tikka" className="bot-menu-logo-pic"></img>
                    </Link>
                </div>
                <div className="bot-menu-icons">
                    <div className="bot-menu-icon bot-icon-linkedin df">
                        <a href={props.settings.linkedin} target="_blank" rel="noreferrer" aria-label="LinkedIn page">
                            <IconContext.Provider value={{color: "#0077B5"}}>
                                <AiFillLinkedin className="db" alt="linkedin"></AiFillLinkedin>
                            </IconContext.Provider>
                        </a>
                        {/* <div className="bot-menu-linkedin bot-menu-open-list">
                        <div className="bot-menu-open-list-in">
                            <div className="bot-icon-open"></div>
                        </div>
                        </div> */}
                    </div>
                    <div className="bot-menu-icon bot-menu-langs">
                        <LanguageSwitcher activeLanguage={props.language || "en"} languages={props.languages} slug={props.slug}></LanguageSwitcher>
                    </div>
                    <div className="bot-menu-icon bot-menu-open posr">
                        <button onClick={()=>setMobileMenuVisible(true)}><img src={iconMenu} alt="TopMenu"></img>
                        </button>
                        <div className="bot-menu-open-list">
                            <div className="bot-menu-open-list-in posr">
                                <div className="bot-menu-list-shadow"></div>
                                <h4><Link to={nav[0].url} className="bot-menu-item-link" activeClassName="bot-menu-open-list-subtitle--active">{nav[0].title}</Link></h4>
    
                                <h4><Link to={nav[1].url} className="bot-menu-item-link">{nav[1].title}</Link></h4>
                                {
                                    props.services && props.services.map((service, i)=>{
                                        return (
                                            <p  key={i} className="bot-menu-item-link bot-menu-open-list-subtitle">
                                                <Link to={slugHandler(props.language, "")+"#"+kebabCase(service.title)}>{service.title}</Link>
                                            </p>
                                        )
                                    })
                                }
                                <h4><Link to={nav[2].url} className="bot-menu-item-link">{nav[2].title}</Link></h4>
                                {
                                    jobs.list && jobs.list.length && jobs.list.map((link, i)=>{
                                        let job = listJobs.filter((j)=>j.frontmatter.slug === `${link.position}` && j.frontmatter.enabled)[0];
                                        return job && (
                                                <p key={i} className="bot-menu-item-link bot-menu-open-list-subtitle">
                                                    <Link to={slugHandler(props.language, "")+"#"+kebabCase(job.frontmatter.slug)}>{job.frontmatter.title}</Link>
                                                </p>
                                                )
                                      })
                                    // jobs && jobs.filter((job)=>job.frontmatter.enabled).map((job, i)=>{
                                    //     return(
                                    //     <p key={i} className="bot-menu-item-link bot-menu-open-list-subtitle">
                                    //         <Link to={slugHandler(props.language, "")+"#"+kebabCase(job.frontmatter.slug)}>{job.frontmatter.title}</Link>
                                    //     </p>
                                    //     )
                                    // })
                                }
                                <h4><Link to={nav[3].url} className="bot-menu-item-link">{nav[3].title}</Link></h4>
                                <h4><Link to={nav[4].url} className="bot-menu-item-link">{nav[4].title}</Link></h4>
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>
            <MobileMenu 
            isVisible={mobileMenuVisible} 
            onClose={()=>setMobileMenuVisible(false)} 
            language={props.language || "en"} 
            languages={props.languages} 
            translation={props.translation} 
            slug={props.slug} 
            jobs={props.jobs} 
            listJobs={props.listJobs}
            services={props.services}
            settings={props.settings}
            ></MobileMenu>
        </nav>
    )
}


export default TopMenu