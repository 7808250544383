import { kebabCase } from "lodash";

export function slugHandler(language, slug) {
  if (!language || language === 'en') {
    return kebabCase(slug).length === 0 ? '/' : `/${kebabCase(slug)}/`
  }
  else {
    return kebabCase(slug).length === 0 ? '/fi/' : `/fi/${kebabCase(slug)}/`
  }
}

export default slugHandler;