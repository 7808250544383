import React from 'react'
import { Link } from 'gatsby'

import slugHandler from '../api/slugHandlerBrowser'

const LanguageSwitcher = ({ activeLanguage, languages, slug }) => {
  // console.log(activeLanguage, languages, slug);

  return (
    <div className="bot-langSwitch">
      {
      languages.map(lang => {
          const url = slugHandler(
            lang,
            slug
          );
          return (
                <Link
                key={lang}
                className={`bot-menu-lang ${activeLanguage === lang ? 'bot-menu-lang--active' : ''}`}
                to={url}
                >
                {lang}&nbsp;
                </Link>
            )
            }
      )}
    </div>
  )
}

export default LanguageSwitcher