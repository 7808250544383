import React from "react"

function Footer(props){
    return(
        
        <section className="bottom-menu">
<div className="bottom-menu__in container-800">
  <p>{props.settings.companyname}, {props.settings.address} • <a href={"mailto:"+ props.settings.email}>{props.settings.email}</a> • <a href={props.settings.linkedin}>LinkedIn</a>
 </p>
</div>
</section>
        
    )
}

export default Footer